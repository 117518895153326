












































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { ITransaction } from '@/interfaces';
import { dispatchGetUserProfile } from '@/store/main/actions';
import { api } from '@/api';

@Component
export default class Plans extends Vue {

  public loading = true;
  public transactions = [] as ITransaction[];
  public snackbarFailure = false;
  public snackbarSuccess = false;
  public is_beta = true;

  public headers = [
    {
      text: 'Transferred',
      sortable: false,
      value: 'transferred',
      align: 'left',
    },
    {
      text: this.mobile ? 'Method:' : 'Method',
      sortable: true,
      value: 'method',
      align: 'left',
    },
    {
      text: this.mobile ? 'Note:' : 'Note',
      sortable: false,
      value: 'note',
      align: 'left',
    },
    {
      text: this.mobile ? 'Date:' : 'Date',
      sortable: true,
      value: 'created',
      align: 'end',
    },
  ];

  @Prop() readonly statusIn: string | undefined;
  public status: string | undefined;
  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    await this.getTransactions();
    if (this.statusIn == 'success') {
      this.snackbarSuccess = true;
    }
    if (this.statusIn == 'failure') {
      this.snackbarFailure = true;
    }
  }

  public async submit(product: string) {
    const token = readToken(this.$store);
    try {
      const response = await api.stripeSessionCreateSubscription(token, product);
      if (response.status === 200) {
        if (response.data.checkout_url) {
          window.location.href = response.data.checkout_url;
        } else {
          await dispatchGetUserProfile(this.$store);
        }
      }
    } catch {
      this.$router.push('/main/user/credits/purchase/canceled');
    }
  }

  public async getTransactions() {
    try {
      const token = readToken(this.$store);
      const response = await api.getTransactions(token, this.userSelf!.id);
      this.transactions = response.data;
    } catch {
      this.transactions = [];
    }
    this.loading = false;
  }

  get userSelf() {
    return readUserProfile(this.$store);
  }

  get userSubscription() {
    if (!this.userSelf?.subscription) return null;
    return this.userSelf.subscription.plan.code;
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }

  public getFormattedDate(date) {
    return new Date(date).toLocaleDateString();
  }

}
